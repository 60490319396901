class APIWrapper {
  constructor() {
    this.state = {data: null}
  }
  async request(params, result) {
    const data = params.data || {}
    let options = new URLSearchParams(data)

    const opt = params.method !== "GET" ? "" : `?${options.toString()}`

    await fetch(`${params.host}${params.what}${opt}`, {
      method: params.method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: params.method === "GET" ? undefined : JSON.stringify(params.data),
    })
      .then((response) => response.json())
      .then((response) => {
        this.state.data = response
        localStorage.setItem(params.storage, JSON.stringify(response))
      })
      .catch((err) => {
        console.log(err)
      })

    return this.state.data
  }
}

module.exports = APIWrapper
