import React from "react"
import {SiAndroid, SiIos, SiMacos, SiWindows} from "react-icons/si"

import {useApp} from "../../hooks/useApp"

export const Features = () => {
  const _app = useApp()

  if (!_app.datas) {
    return <p>Загрузка...</p>
  }

  const box = _app.datas.map((item) => {
    return (
      <div className="Box_Item" key={item.id}>
        <p className="label">{item.label}</p>
        <a href={item.url}>
          <img
            src={item.img}
            width={200}
            height={200}
            alt="lorem"
            className="item_app"
          />
        </a>
        <div className="platform">
          <a href={item.android === "" ? "#" : item.android}>
            <SiAndroid
              size={30}
              color={item.android === "" ? "gray" : "white"}
            />
          </a>
          <a href={item.ios === "" ? "#" : item.ios}>
            <SiIos size={30} color={item.ios === "" ? "gray" : "white"} />
          </a>
          <a href={item.macos === "" ? "#" : item.macos}>
            <SiMacos size={30} color={item.macos === "" ? "gray" : "white"} />
          </a>
          <a href={item.windows === "" ? "#" : item.windows}>
            <SiWindows
              size={30}
              color={item.windows === "" ? "gray" : "white"}
            />
          </a>
        </div>
      </div>
    )
  })

  return (
    <div className="container-fluid d-flex justify-content-center">
      <div className="col-lg-8">
        <div className="Box">{box}</div>
      </div>
    </div>
  )
}
