const Config = require("../config/index.js")

const APIWrapper = require("./wrapper")

class APIMethods extends APIWrapper {
  appAll() {
    return this.request({
      what: "app/",
      host: Config.API,
      data: {},
      method: "GET",
      storage: "app",
    })
  }
}

const API = new APIMethods({})

module.exports = API
