// import {useQuery} from "react-query"
import {useAsync} from "./useAsync"
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "react-query"

const API = require("../API")

export const useApp = () => {
  const {data, error} = useAsync("app")
  const {isLoading, data: app} = useQuery("app_list", () => API.appAll())
  const datas = !data ? app : data
  return {isLoading, datas, error}
}
