import {useState, useEffect} from "react"

export const useAsync = (name) => {
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)

  const getData = async () => {
    try {
      const value = await localStorage.getItem(name)
      if (value !== null) {
        setData(JSON.parse(value))
        // console.log(JSON.parse(value))
        // value previously stored
      }
    } catch (e) {
      setError(e)
      // error reading value
    }
  }

  useEffect(() => {
    getData()
  }, [])
  return {data, error}
}
